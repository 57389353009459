<template>
    <main class="container">
        <div class="section">
            <h1>About Us</h1>
            <p>Welcome to Thermalwatch!</p>
            <p>We are dedicated to providing thermal data straight from NASA's ECOSTRESS satellite. This project is from Northern Arizona University.</p>
        </div>
        <div class="section">
            <h2>Our Mission</h2>
            <p>Our mission is to display thermal tempurature data in a user friendly interface.</p>
        </div>
        <div class="section">
            <h2>Contact Us</h2>
            <p>If you have any questions or inquiries, or you feel like contributing to this project you can contact us at <a href="https://github.com/UnlimitedDrip/SatelliteFirePatrol">Github</a>.</p>
        </div>
    </main>
</template>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
}

.section {
    margin-bottom: 20px;
}
</style>
